.category-title {
  font-family: $o-serif-font-family;
  font-size: 2.5em;
  font-weight: 600;
  color: $o-brand-primary;
}

.category-subtitle {
  font-family: $o-base-font-family;
  font-size: 1.1em;
  color: $o-text;
  line-height: 1.5;
}

.category-section-title {
  font-family: $o-serif-font-family;
  font-size: 1.6em;
  font-weight: 600;
  color: $o-brand-primary;
}

.copyright {
  font-family: $o-base-font-family;
  font-size: 0.8em;
  font-weight: 300;
  color: #9c9c9c;
}

.role-select {
  font-family: $o-base-font-family;
  font-size: 1em;
  font-weight: 300;
  color: $o-text;
}
