.markdown-body {
  font-family: $o-base-font-family;
  font-size: 1em;
  line-height: 1.5;
  color: $o-text;
  word-wrap: break-word;

  * {
    box-sizing: border-box;
  }

  [type="checkbox"] {
    padding: 0;
  }

  input {
    margin: 0;
    overflow: visible;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a {
    color: $o-link;
    text-decoration: underline;
    background-color: transparent;

    &:active,
    &:hover {
      color: $o-link-hover;
      text-decoration: underline;
      outline-width: 0;
    }

    &:visited {
      color: $o-link-visited;
    }
  }

  strong {
    font-weight: 600;
  }

  p {
    margin-top: 0;
    margin-bottom: 0.625em;
  }

  ol ol,
  ul ol {
    list-style-type: lower-roman;
  }

  dd {
    margin-left: 0;
  }

  code,
  kbd,
  pre {
    font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
    font-size: 1em;
  }

  code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 0.75em;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 0.1875em;
  }

  kbd {
    display: inline-block;
    padding: 0.1875em 0.3125em;
    line-height: 0.625em;
    color: #444d56;
    vertical-align: middle;
    background-color: #fafbfc;
    border: solid thin #d1d5da;
    border-bottom-color: #c6cbd1;
    border-radius: 0.1875em;
    box-shadow: inset 0 -0.0625em 0 #c6cbd1;
  }

  pre {
    padding: 1em;
    margin-top: 0;
    margin-bottom: 0;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    word-wrap: normal;
    background-color: #f6f8fa;
    border-radius: 0.1875em;

    > code {
      padding: 0;
      margin: 0;
      font-size: 100%;
      white-space: pre;
      background: transparent;
      border: 0;
    }

    code {
      display: inline;
      max-width: 100%;
      padding: 0;
      margin: 0;
      overflow: visible;
      line-height: inherit;
      word-wrap: normal;
      background-color: transparent;
      border: 0;
    }
  }

  a:not([href]) {
    color: inherit;
    text-decoration: none;
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  pre {
    margin-top: 0;
    margin-bottom: 1em;
  }

  hr {
    height: 0.25em;
    box-sizing: content-box;
    padding: 0;
    margin: 1.5em 0;
    overflow: hidden;
    background-color: #e1e4e8;
    border: 0;
    border-bottom: thin solid #eee;

    &::before {
      display: table;
      content: "";
    }

    &::after {
      display: table;
      clear: both;
      content: "";
    }
  }

  blockquote {
    padding: 0 1em;
    margin: 0;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0.625em;
    margin-bottom: 1.625em;
    font-weight: 600;
    line-height: 1.25;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0.85em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.875em;
  }

  h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  ul {
    list-style: circle;

    li {
      padding-left: 0.5em;
    }
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;

    p {
      padding-left: 0.5em;
    }
  }

  ul ul,
  ul ol,
  ol ol,
  ol ul {
    padding-left: 3em;
    margin-top: 0;
    margin-bottom: 0;
  }

  li > p {
    margin-top: 1em;
  }

  li + li {
    margin-top: 0.25em;
  }

  dl {
    padding: 0;
  }

  dl dt {
    padding: 0;
    margin-top: 1em;
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
  }

  dl dd {
    padding: 0 1em;
    margin-bottom: 1em;
  }

  table {
    display: block;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;
  }

  td,
  th {
    padding: 0;
  }

  table th {
    font-weight: 600;
  }

  table th,
  table td {
    padding: 0.375em 0.8125em;
    border: thin solid #dfe2e5;
  }

  table tr {
    background-color: #fff;
    border-top: thin solid #c6cbd1;
  }

  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: #fff;
  }
}
