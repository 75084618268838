.header {
  background-color: $o-brand-primary;

  &__container {
    max-width: $o-container-max-width;
    padding: $o-side-gutters;
    margin: 0 auto;
  }

  &__logo {
    max-width: 7em;
    vertical-align: middle;
    margin-right: 2em;
  }

  &__title {
    font-family: $o-base-font-family;
    color: $o-inverse;
    font-size: 1.4em;
  }
}
