.alert {
  display: block;
  padding: 1.5em;
  border-left: 0.5em solid #6a737d;
  border-top: 1px solid #6a737d;
  border-right: 1px solid #6a737d;
  border-bottom: 1px solid #6a737d;

  font-family: $o-base-font-family;
  font-size: 1em;
  font-weight: 600;
  color: #f19c02;
  border-radius: 0.25em;

  &--warning {
    border-left: 0.5em solid #f19c02;
    border-top: 1px solid #f19c02;
    border-right: 1px solid #f19c02;
    border-bottom: 1px solid #f19c02;
  }
}
