.radio-nav {
  display: flex;
  width: 100%;
  border-radius: 1em;
  background-color: #eee;

  &__item {
    flex-grow: 1;

    &.is-active {
      background-color: $o-brand-primary;
    }

    &:first-child {
      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
    }

    &:last-child {
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
    }

    &:focus-within {
      position: relative;
      outline: none;
      box-shadow: 0 0 0 $o-focus-border-width $o-focus;
    }
  }

  &__link {
    display: block;
    padding: 0.5em;
    font-family: $o-base-font-family;
    text-decoration: none;
    text-align: center;
    color: #818181;

    &:focus {
      outline: none;
    }

    &.is-active, .is-active & {
      color: $o-inverse;
    }
  }
}
