/**
 * Foundation
 *
 * The foundation is where styles that apply to all applications
 * can be set. This file is RISKY and shouldn't be touched. There is only specific scenarios
 * that foundation styles should be used.
 */

@import "../variables";

* {
  box-sizing: border-box;
}

.body-reset {
  min-width: 320px;
  padding: 0;
  margin: 0;
}
