.l-categories {
  border-top: 2px solid #eee;

  @media (min-width: $o-lg-breakpoint) {
    background: linear-gradient(90deg, #f8f8f8 50%, $o-inverse 50%);
  }

  &__container {
    display: block;
    max-width: $o-container-max-width;
    margin: 0 auto;

    @media (min-width: $o-lg-breakpoint) {
      display: flex;
    }
  }

  &__nav {
    min-width: 22em;
    border-right: 2px solid #eee;
    margin-bottom: 1.5em;
    background-color: #f8f8f8;
  }

  &__content {
    flex-grow: 1;
    background-color: $o-inverse;
    border-right: 1px solid #eee;
  }

  &__levels {
    padding: $o-side-gutters $o-side-gutters 0;
  }

  &__header {
    padding: $o-side-gutters;
    border-bottom: 1px solid #eee;
  }

  &__title {
    margin-bottom: 0.2em;
  }

  &__description {
    padding: $o-side-gutters;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__section-title {
    margin-bottom: 0.8em;
  }

  &__footer {
    padding: $o-side-gutters;
  }
}
