/*
 * Variables
 *
 * Set project specific variables here.
 * Overrides should be placed in the overrides file.
 */

// Colours

$o-brand-primary: #101f43;
$o-brand-secondary: #ba0c2f;

$o-primary: #36404e;
$o-inverse: #fff;

$o-go: #009111;
$o-go-hover: darken($o-go, 5%);

$o-secondary: #cbcbcb;
$o-secondary-hover: darken($o-secondary, 5%);

$o-danger: #ae0300;
$o-danger-hover: darken($o-danger, 5%);

$o-success: #48af54;
$o-success-hover: darken($o-success, 5%);

$o-text: #1e1e1e;
$o-inverse: #fff;

$o-link: #1c3ca8;
$o-link-hover: darken($o-link, 7%);
$o-link-visited: #693680;

$o-light-gray: #e1e1e1;

// Accessibility

$o-focus: #ffa75a;
$o-focus-border-width: 0.2em;

// Typography

$o-demo-font-family: "Open Sans";
$o-base-font-family: $o-demo-font-family, Arial, "Helvetica Neue", Helvetica, sans-serif;
$o-serif-font-family: "Georgia", serif;

// Navigation

$o-nav-color: #fff;

// Style

$o-border-radius: 0.25em;

// Dimensions

$o-side-gutters: 2em;
$o-sm-side-gutters: 1em;
$o-md-side-gutters: 1em;

$o-min-nav-width: 640px;
$o-min-table-width: 960px;
$o-container-max-width: 1400px;

// Background

$o-bg-name: "background.png";
$o-img-path: "../../img/promotions/background/";
$o-bg-image: "#{$o-img-path}#{$o-bg-name}";
$o-main-background: url("#{$o-bg-image}");

// Responsiveness

$o-sm-breakpoint: 576px;
$o-sm-breakpoint-max: $o-sm-breakpoint - 1px;

$o-md-breakpoint: 768px;
$o-md-breakpoint-max: $o-md-breakpoint - 1px;

$o-lg-breakpoint: 992px;
$o-lg-breakpoint-max: $o-lg-breakpoint - 1px;

$o-xl-breakpoint: 1200px;
$o-xl-breakpoint-max: $o-xl-breakpoint - 1px;

// Forms

$o-input-border: thin solid $o-light-gray;
$o-input-margin: 2.25em 0;

:export {
  secondary: $o-brand-primary;
  complementary: $o-brand-secondary;
}
