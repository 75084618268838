.select {
  position: relative;

  &:after {
    pointer-events: none;
    position: absolute;
    right: 1em;
    bottom: 1.125em;
    content: '\25BC';
  }

  &--error:after {
    color: $o-danger;
  }

  &__input {
    display: block;
    width: 100%;
    padding: 0.9em .75em;
    background-color: $o-inverse;
    border: $o-input-border;
    border-radius: $o-border-radius;
    font-family: $o-base-font-family;
    font-size: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: border-color 0.2s, border-left-width 0.2s;

    &:focus {
      box-shadow: 0 0 0 $o-focus-border-width $o-focus;
      outline: none;
    }

    &--error {
      border-color: $o-danger;
      border-left-width: 1em;
    }

    &--blended:not(:focus):not(&--error) {
      display: inline;
      background-color: transparent;
      border: 1px solid transparent;
      text-decoration: underline;
      text-decoration-style: dashed;
      text-underline-position: under;
      text-decoration-color: #979797;
    }
  }
}
