.role-selector {
  display: block;
  padding-bottom: 3em;

  &__label {
    font-family: $o-base-font-family;
    font-size: 1em;
    font-weight: 300;
    color: $o-text;
    margin-bottom: 0.5em;
  }
}
