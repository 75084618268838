.intro {
  &__title {
    font-family: $o-serif-font-family;
    font-size: 2.2em;
    font-weight: 600;
    color: $o-inverse;
  }

  &__content {
    font-family: $o-base-font-family;
    font-size: 1.3em;
    font-weight: 300;
    color: $o-inverse;
    line-height: 1.5;

    @media (min-width: $o-md-breakpoint) {
      columns: 2;
    }
  }

  &__paragraph {
    margin-bottom: 1em;

    em {
      font-style: italic;
    }
  }

  &__header {
    padding: 2em 0;
  }

  &__close {
    display: block;
    float: right;
    width: 2em;
    height: 2em;
    padding: 0.5em;
    border: 0;
    border-radius: 1em;
    background-color: #ffffff17;
    cursor: pointer;
    -webkit-appearance: none;

    &:focus-within {
      outline: none;
      box-shadow: 0 0 0 $o-focus-border-width $o-focus;
    }
  }

  &__close-icon {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__description {
    columns: 2;
  }
}
