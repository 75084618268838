.l-chart {
  display: block;
  max-width: $o-container-max-width;
  margin: 0 auto;
  padding: $o-side-gutters;

  @media (min-width: $o-lg-breakpoint) {
    display: flex;
  }
}

.l-chart__config {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 20em;
}

.l-chart__display {
  flex-grow: 1;
}

.l-chart__person {
  margin-bottom: 1em;
}
