.list-nav {
  padding: 2em;

  &__item {
    border-radius: 0.2em;
    border: $o-focus-border-width solid transparent;
    margin-bottom: 0.1em;

    &:focus-within {
      outline: none;
      background-color: #eee;
      box-shadow: 0 0 0 $o-focus-border-width $o-focus;
    }

    &.is-active, .is-active & {
      background-color: $o-brand-primary;
    }
  }

  &__link {
    display: block;
    padding: 0.75em;
    font-family: $o-base-font-family;
    text-decoration: none;
    color: $o-text;

    &.is-active, .is-active & {
      color: $o-inverse;
    }

    &:focus {
      outline: none;
    }
  }
}
